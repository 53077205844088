<template>
  <div class="job-detail wrapper page-wrap" v-if="job">
    <a-spin :spinning="loading">
      <div class="flex flex-between">
        <div class="job-name mb-10 font-30 font-bold">{{ job.job_name }}</div>
        <div class="salary">
          {{ job.job_salary_min }}-{{ job.job_salary_max }}
        </div>
      </div>
      <div class="font-18 mb-10">{{ job.job_company_name }}</div>
      <div class="font-16">
        <FieldTimeOutlined />&nbsp;&nbsp;更新于{{
          job.job_update_time ? job.job_update_time : job.job_create_time
        }}
      </div>

      <a-divider orientation="left">职位描述</a-divider>
      <div class="font-18 mb-10">招{{ job.job_num }}人</div>
      <div class="font-18" style="line-height: 2">
        {{ job.job_introduction }}
      </div>
      <a-divider orientation="left">联系方式</a-divider>
      <div style="line-height: 2" class="font-18">
        <div>联系人：{{ job.job_contact_name }}</div>
        <div>联系方式：{{ job.job_contact_phone }}</div>
        <div>联系邮箱：{{ job.job_contact_email }}</div>
        <div>公司地址：{{ job.job_company_address }}</div>
      </div>
      <div class="flex handle-box" v-if="job.user_id == user_id">
        <a-button class="job-btn" @click="updateJobStatusDataFun"
          >删除职位</a-button
        >
        <a-button type="primary" class="job-btn" @click="goJobForm"
          >修改职位</a-button
        >
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getJobData, updateJobStatusData } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { encode, decode } from "js-base64";
import { fromType } from "vue-types";
import { FieldTimeOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "JobDetail",
  components: {
    FieldTimeOutlined
  },
  setup() {
    const route = useRoute();
    const pageData = reactive({
      job: null,
      jobId: null,
      loading: true,
      user_id: computed(() => getUserId())
    });
    const getJobDataFun = _jobId => {
      getJobData({ job_id: _jobId })
        .then(res => {
          if (res.code == 200) {
            pageData.job = res.data.job;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const updateJobStatusDataFun = () => {
      updateJobStatusData({
        job_id: pageData.jobId,
        job_status: -1,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            message.success("操作成功");
            url.replaceTo("/job");
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const goJobForm = () => {
      url.navigateTo("/job/form", {
        t: encode(`${pageData.jobId}`)
      });
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/job/detail") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.jobId = query[0];
            getJobDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.job = null;
            message.error("暂无公告信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      updateJobStatusDataFun,
      goJobForm
    };
  }
});
</script>
<style lang="less">
.job-detail {
  padding-top: 40px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .salary {
    color: #1276cb;
    font-size: 30px;
    font-weight: bold;
  }
  .ant-divider-horizontal.ant-divider-with-text {
    margin: 30px 0;
  }
  .ant-divider-inner-text {
    font-size: 20px;
    font-weight: bold;
  }
  .ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 0;
  }
  .ant-divider-inner-text {
    padding-left: 0;
  }
  .handle-box {
    justify-content: center;
    margin: 40px 0;
    .job-btn {
      width: 400px;
      height: 60px;
      border-radius: 30px;
      font-size: 18px;
      margin: 0 20px;
    }
    .active-btn {
      background-color: #1276cb;
    }
  }
}
</style>
